
import { Component, Vue, Prop } from 'vue-property-decorator';

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
];

let index = 0;

@Component
export default class InfoCard extends Vue {
  @Prop({ required: true, type: String }) readonly title!: string;
  currentColor = colors[index % colors.length];
  created() {
    index++;
  }
}
