<template>
  <div class="text-center">
    <div class="text-red">
      <h1>Error 404 - Not Found</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style></style>
